import { applyPolyfill } from './closest_polyfill';

(function () {
  applyPolyfill()

  const localeMeta = document.querySelector('meta[name="language-locale"]')
  const originalLocale = localeMeta ? localeMeta.content : null
  const currencyMeta = document.querySelector('meta[name="currency"]')
  const originalCurrency = currencyMeta ? currencyMeta.content : null

  function initHeader () {
    const expandMenuListener = (event) => {
      const isExpanded = event.target.getAttribute('data-expanded')
      if(isExpanded == 'true') {
        dismissHeaderSubMenu(event.target)
      } else if (isExpanded == 'false') {
        showHeaderSubMenu(event.target)
      }
    }

    // Desktop
    const iconButton = document.querySelector('.js-submenu-account')
    const hostMenuButton = document.querySelector('.js-host-menu-button')

    if(iconButton && hostMenuButton) {
      document.addEventListener('click', event => {
        if(!event.target.closest('.js-submenu-account') && !event.target.closest('.js-header-popup-account-menu')) {
          dismissHeaderSubMenu(document.querySelector('.js-submenu-account'))
        }
        if(!event.target.closest('.js-host-menu-button') && !event.target.closest('.js-header-popup-host-menu')) {
          dismissHeaderSubMenu(document.querySelector('.js-host-menu-button'))
        }
      })
    }

    if(iconButton) {
      iconButton.onclick = expandMenuListener
    }

    if(hostMenuButton) {
      hostMenuButton.onclick = expandMenuListener
    }

    // Mobile
    const menu = document.querySelector('.js-hamburger-button')
    if (menu) {
      menu.onclick = () => {
        const menuWindow = document.querySelector('.js-sp-menu-window')
        menuWindow.classList.add('open')
        document.body.classList.add('no-scrolling')
      }
    }

    const close = document.querySelector('.js-sp-menu-window-close')
    if (close) {
      close.onclick = () => {
        const menuWindow = document.querySelector('.js-sp-menu-window')
        menuWindow.classList.remove('open')
        document.body.classList.remove('no-scrolling')

        const matches = menuWindow.querySelectorAll('[data-expanded]')
        Array.prototype.forEach.call(matches, element => {
          dismissHeaderSubMenu(element)
        })
      }
    }

    const spAccountMenuButton = document.querySelector('.js-sp-submenu-account')
    const spHostMenuButton = document.querySelector('.js-sp-host-menu-button')

    if(spAccountMenuButton) {
      spAccountMenuButton.onclick = expandMenuListener
    }

    if(spHostMenuButton) {
      spHostMenuButton.onclick = expandMenuListener
    }
  }

  function showHeaderSubMenu (element) {
    element.setAttribute('data-expanded', 'true')
  }

  function dismissHeaderSubMenu (element) {
    element.setAttribute('data-expanded', 'false')
  }

  function bindLanguageSwitches () {
    const selects = document.querySelectorAll('.js-lang-selector')
    Array.prototype.forEach.call(selects, (changeLocaleSelect) => {
      // 起こる可能性は低いけど、JSの読み込みが終わる前に切り替えた場合の対策
      if(originalLocale && originalLocale != changeLocaleSelect.value) {
        switchLocaleIfChanged(changeLocaleSelect.value)
      }
      changeLocaleSelect.addEventListener('change', function (evt) {
        switchLocaleIfChanged(evt.target.value)
      })
    })

    document.body.setAttribute('data-locale-switch-state', 'ready')
  }

  /**
   *
   * @param {String} locale
   */
  function setLocaleLabel (locale) {
    const label = document.querySelector('.js-lang-label')
    if (!label) { return }
    switch(locale) {
    case 'ja':
      label.innerText = 'JP'
      break
    case 'zh-TW':
      label.innerText = 'TW'
      break
    default:
      label.innerText = locale.toUpperCase()
    }
  }

  function switchLocaleIfChanged(locale) {
    setLocaleLabel(locale)
    if (locale !== originalLocale) {
      const params = new URLSearchParams()
      params.set('redirect_path', `${window.location.pathname}${window.location.search}`)
      window.location.assign(`/change_locale/${locale}?${params.toString()}`)
    }
  }

  function bindCurrencySwitches () {
    const selects = document.querySelectorAll('.js-currency-selector')
    Array.prototype.forEach.call(selects, (changedCurrencySelec) => {
      if(originalCurrency && originalCurrency != changedCurrencySelec.value) {
        switchCurrencyIfChanged(changedCurrencySelec.value)
      }
      changedCurrencySelec.addEventListener('change', function (evt) {
        switchCurrencyIfChanged(evt.target.value)
      })
    })

    document.body.setAttribute('data-currency-switch-state', 'ready')
  }

  function switchCurrencyIfChanged(currency) {
    setCurrencyLabel(currency)
    if (currency !== originalCurrency) {
      const params = new URLSearchParams(window.location.search.substring(1))
      params.set('selected_currency', currency)
      window.location = `${window.location.pathname}?${params.toString()}`
    }
  }

  function setCurrencyLabel (currency) {
    const label = document.querySelector('.js-currency-label')
    if (!label) { return }
    switch(currency) {
    case 'JPY':
      label.innerText = 'JPY'
      break
    case 'USD':
      label.innerText = 'USD'
      break
    case 'TWD':
      label.innerText = 'TWD'
      break
    case 'CHY':
      label.innerText = 'CHY'
      break
    case 'KRW':
      label.innerText = 'KRW'
      break
    default:
      label.innerText = originalCurrency
    }
  }

  initHeader()
  if(originalLocale) {
    setLocaleLabel(originalLocale)
  }

  if(originalCurrency) {
    setCurrencyLabel(originalCurrency)
  }

  try {
    if (new URLSearchParams('q=%2B').get('q') !== '+') {
      throw new Error('')
    }
    bindLanguageSwitches()
    bindCurrencySwitches()
  } catch (error) {
    window.URLSearchParams = void 0
    const scriptUrl = document.getElementById('url-search-params-polyfill-src').value
    const script = document.createElement('script')
    script.src = scriptUrl
    script.onload = () => {
      bindLanguageSwitches()
      bindCurrencySwitches()
    }
    document.head.appendChild(script)
  }

})()
